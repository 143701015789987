import * as React from "react";
import '../styles/announcemant.scss'

function Banner(props) {
    return (
        <div
            className={"w-full h-full flex flex-col justify-content-end align-bottom align-items-center banner_announcement"}
        >
            Se pot face avize / sedinte online!
        </div>
    )
}

export default Banner