import * as React from "react"
import {useMemo} from "react";
import _ from "lodash";
import {Grid} from "@material-ui/core";
import splitContactPrismic from "../lib/structure_data";
import '../styles/contact.scss'
import '../styles/general.scss'
import WhatsUpContact from "./WhatsUpContact";
import {isMobile} from "react-device-detect";
import '../styles/services.scss';
import {renderJsonLdProduct} from "../lib/ldjson";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBook} from "@fortawesome/free-solid-svg-icons";
import {slugify} from "../lib/app_string";
import {Link} from "gatsby";

export default function ServiceList(props) {
    const {allPrismicServices, showTitle, prismicContact, testimonials} = props
    // const {pageContext} = props;
    // const {id} = pageContext;
    const filterCategoryId = props.filterCategoryId || "";
    const filterSubCategory = props.filterSubCategory || "";
    const showDetails = props.showDetails || false;
    const {showSearch} = props;
    const [searchText, setSearchText] = React.useState("");

    allPrismicServices.edges.sort ( (edgeLeft, edgeRight) => {
        if (edgeRight.node.data.priority > edgeLeft.node.data.priority) {
            return -1;
        }
        if (edgeRight.node.data.priority === edgeLeft.node.data.priority) {
            return 0
        }

        return 1;
    })

    const {
        phoneNumbers,
        emails,
        locations,
        whatsups,
        programs
    } = splitContactPrismic(prismicContact)

    const searchChange = (searchTextArg) => {
        setSearchText((searchTextArg))
    }

    const canShowAnySubCategory = (service_categories, nodeId) => {
        let canUse = false;
        service_categories.map((serviceCategory, kk) => {
            if (canShowServiceCategoryItem(serviceCategory.category_name.text)) {
                if (
                    (!_.isEmpty(filterCategoryId) && nodeId === filterCategoryId)
                    || _.isEmpty(filterCategoryId)
                ) {
                    canUse = true;
                }
            }
        })



        return canUse;
    }

    const canShowServiceCategoryItem = (serviceCategoryItem) => {
        // console.log("cmp1", serviceCategoryItem, "...." ,filterSubCategory)
        if (!_.isEmpty(filterSubCategory) && filterSubCategory.toLowerCase() !== serviceCategoryItem.toLowerCase()) {
            return false;
        }

        if (searchText === "" || _.isEmpty(searchText)) {
            return true;
        }

        const words = searchText.toLowerCase().split(" ");
        for (let i=0; i < words.length; i++) {
            if (serviceCategoryItem.toLowerCase().indexOf(words[i].toLowerCase()) !== -1) {
                return true;
            }
            if (words[i].toLowerCase().indexOf(serviceCategoryItem.toLowerCase()) !== -1) {
                return true;
            }
        }

        return false;
    }

    const padddingBox = (isMobile) ? '0vh': '0vh';

    const generateLink = (serviceCategory, serviceCategoryItem) => {
        return '/services/' + slugify(serviceCategory) + "/" + slugify(serviceCategoryItem.toLowerCase())  + "/"
    }

    const renderServiceList = () =>  (
        <Grid item sm={12} xl={12} lg={12} md={12} >
            <div className={"w-full flex flex-column justify-content-start align-items-start align-middle"} style={{paddingLeft: padddingBox, paddingRight: padddingBox}}>
                {showTitle && (
                    <div className={"w-full flex flex-col justify-content-center align-middle align-items-center mb-8"}>
                        <div className={"heading_1 primary_heading_1_title color_green_light font-bold"}  style={{color: '#1da341', fontSize: '32px'}}>
                            Servicii si preturi
                        </div>

                        {showSearch && (
                            <div className={"w-full flex flex-row justify-content-center align-middle align-items-center"}>
                                <input
                                    placeholder={"Cautare servicii"}
                                    style={{ width: '70%', borderBottom: '3px solid #1da341'}}
                                    value={searchText}
                                    onChange={ev => searchChange(ev.target.value)}
                                />
                            </div>
                        )}
                    </div>
                )}

                <div className={"w-full services_list"}>
                    {allPrismicServices.edges.map((el, k) => {
                        const canListThis = canShowAnySubCategory(el.node.data.service_categories, el.node.id);
                        if (!canListThis) return <React.Fragment></React.Fragment>

                        return (
                            <div style={{border: 'none'}} key={k}>
                                <div style={{padding: 0, margin: '0px'}}>
                                    <div className={"w-full flex flex-row justify-content-start align-middle align-items-start"}>
                                        <div>
                                            <div className={"primary_heading_1_title serice_cateogry"} style={{marginBottom: '0px'}}>
                                                <FontAwesomeIcon
                                                    icon={faBook}
                                                    color={'#32a852'}
                                                    className={"mr-4 list_icon"}
                                                    title={'email'}
                                                />
                                                <b>{el.node.data.service_base_categori.text.toUpperCase()}</b>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div style={{border: 'none', backgroundColor: '#f0f2f0'}}>
                                    {el.node.data.service_categories.map((serviceCategory, kk) => {
                                        if (!canShowServiceCategoryItem(serviceCategory.category_name.text)) {
                                            return <React.Fragment></React.Fragment>
                                        }

                                        const linkUrl = generateLink(el.node.data.service_base_categori.text, serviceCategory.category_name.text)

                                        return (
                                            <div className={"w-full service_category_item_list"} style={{borderBottom: '1px solid #dddddd'}} key={kk}>
                                                <div className={"w-full flex flex-row justify-content-between align-content-between align-middle"} >
                                                    <div className={"primary_heading_2_title  ml-16"}>
                                                        {serviceCategory.category_name.text.toLowerCase()}
                                                    </div>
                                                    <div className={"service_list_price"}>
                                                        <div className={"mr-4"}>
                                                            {serviceCategory.price.text}
                                                        </div>
                                                        {!showDetails && (
                                                            <Link to={linkUrl} className={"text_blue_link_color mr-4"}>Detalii</Link>
                                                        )}

                                                        <div className={"mt-2"}>
                                                            {whatsups && whatsups.id && (
                                                                <WhatsUpContact
                                                                    whatsupItem={whatsups.items[0]}
                                                                    showText={false}
                                                                    message={"Buna, ma intereseaza " + serviceCategory.category_name.text.toLowerCase()}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {!_.isEmpty(serviceCategory.description.text) && showDetails && (
                                                    <div className={"w-full flex-column justify-content-start align-middle align-items-start"}>
                                                        <div className={"primary_body_1_title ml-32"}>
                                                            {serviceCategory.description.text}
                                                        </div>
                                                    </div>
                                                )}

                                                <script
                                                    type="application/ld+json"
                                                    nonce={"mjkljkl78988KHJKLKkhkljkldsjgjkjjhjhjbhijsvgkjhoirfujkk"}
                                                    dangerouslySetInnerHTML={{ __html: JSON.stringify(renderJsonLdProduct(serviceCategory, testimonials)) }}
                                                />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </Grid>
    )

    const memoizedValue = useMemo(() => renderServiceList(), [allPrismicServices, searchText, renderServiceList]);

    return (<>{memoizedValue}</>)

}
